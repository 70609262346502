<!--
 * @Descripttion: 
 * @FilePath: /sxexpress/src/views/searchtracking/index-wx.vue
 * @Author: 张兴业
 * @Date: 2021-04-14 11:53:14
 * @LastEditors: 张兴业
 * @LastEditTime: 2021-07-16 09:19:01
-->
<template>
  <div class="wrap fw" @click="hidenAsideRight">
    <!--START: Header -->

    <div class="header">
      <div class="top tr hidden-phone">
        <div class="w1180">
          <div class="fl">
            您好，欢迎来到三象速递官网
          </div>
          <div class="fr">
            www.sxexpress.com.au
          </div>
          <div class="cl"></div>
        </div>
      </div>

      <div class="w1180 clearfix">
        <h2 class="fl pt10 pb10 logo">
          <a href="/"><img src="../../assets/images/logo.png"/></a>
        </h2>
        <div class="fr pt10 pb10 phone-nav hidden-pc">
          <i class="el-icon-s-unfold" @click.stop="changeMenu"></i>
        </div>
        <div class="fr mt20 ml30 hidden-phone" style="position: relative;">
          <ul>
            <li class="fr ml20">
              <a
                href="javascript:;"
                onmouseover="document.getElementById('weixinDIV').style.display = 'block';"
                onmouseout="document.getElementById('weixinDIV').style.display = 'none';"
              >
                <img
                  src="../../assets/images/top-wx.png"
                  alt="与微信客服交流"
                />
              </a>
            </li>
            <li class="fr">
              <a
                href="http://wpa.qq.com/msgrd?v=3&amp;uin=3376684454&amp;site=qq&amp;menu=yes"
              >
                <img src="../../assets/images/top-qq.png" alt="与QQ客服交流" />
              </a>
            </li>
          </ul>
          <div
            id="weixinDIV"
            onmouseover="this.style.display = 'block';"
            onmouseout="this.style.display = 'none';"
            style="position: absolute; right: 0px; top: 45px; display: none; z-index: 10000;"
          >
            <img src="../../assets/images/wx.jpg" style="width: 140px;" />
          </div>
        </div>
        <div class="nav fr hidden-phone">
          <ul>
            <li><a href="/">首页</a></li>
            <!-- <li><a href="news.aspx">活动公告</a></li> -->
            <li><a href="/service">特色服务</a></li>
            <li><a href="/about">关于三象</a></li>
            <li><a href="/contact">联系三象</a></li>
          </ul>
        </div>
      </div>
    </div>

    <!--END: Header -->
    <div class="search_ewe'">
      <!-- 顶部信息 -->
      <div class="top_banner">
        <img src="@/assets/images/user_banner.jpg" alt="" class="top_img" />
        <div class="top_font">
          <div class="line"></div>
          <div>包裹追踪</div>
        </div>
      </div>
      <div class="con_con">
        <div class="con_con_top">
          <!-- 查询输入 -->
          <div class="bottom">
            <div class="font_title_ewe">
              <span>输入订单号</span>
            </div>
            <div class="title_en">Order number</div>
          </div>
          <!-- 查询按钮 -->
          <div class="search_con">
            <div>
              <textarea
                type="textarea"
                rows="3"
                class="input_class"
                v-model="no"
                style="padding: 8px 12px;"
              />
            </div>
            <div v-loading="submitting" class="btn_search" @click="startSearch">
              追踪/TRACK
            </div>
          </div>
        </div>
        <!-- 线 -->
        <div class="line_line"></div>
        <!-- 订单信息 -->
        <div class="bottom bottom1">
          <div class="font_title_ewe">
            <span>追踪结果</span>
            <span
              style="float: right; color: #079af1; cursor: pointer;"
              @click="goUpload"
            >
              上传身份证
            </span>
          </div>
          <div class="title_en">TRACK RESULTS</div>
        </div>
        <!-- 订单详情 -->
        <div v-show="orderList.length === 1" class="order_order_table">
          <div class="order_det">
            <div class="order_item">
              <div class="item_title">运 单 号：</div>
              <div class="item_det">{{ orderList[0].boxNo }}</div>
            </div>
            <div v-if="orderList[0].details">
              <div class="order_item">
                <div class="item_title">出 发 地：</div>
                <div class="item_det">
                  {{ orderList[0].departure }}
                </div>
              </div>
              <div class="order_item">
                <!-- 目 的 地：江苏省 南通市 -->
                <div class="item_title">目 的 地：</div>
                <div class="item_det">
                  {{ orderList[0].destination }}
                </div>
              </div>
              <div class="order_item">
                <!-- 件 数：1 -->
                <div class="item_title">件 数：</div>
                <div class="item_det">1</div>
              </div>
              <div class="order_item">
                <!-- 转运单号： -->
                <div class="item_title">转运单号：</div>
                <div class="item_det">
                  {{ orderList[0].transferNo }}
                </div>
              </div>
            </div>
            <div class="order_item">
              <div class="item_title">状 态：</div>
              <div class="item_det">
                {{ orderList[0].reminder | reminder }}
              </div>
              <!-- 状
    态：待入库。收件人的身份证信息与运单信息已成功匹配。现正等待包裹入库处理，请给予耐心，谢谢配合。 -->
            </div>
          </div>
          <!-- 单个个订单的table -->
          <!-- <el-steps direction="vertical" :active="1">
            <el-step
              v-for="(item, index) in orderList[0].details || []"
              :key="index"
              :title="item.dateString"
            >
              <div slot="description">
                <div>{{ item.place }}</div>
                <div>{{ item.message }}</div>
              </div>
            </el-step>
          </el-steps> -->
          <van-steps direction="vertical" :active="0" active-color="#079AF1">
            <van-step
              v-for="(item, index) in singleData"
              :key="index"
              :title="item.dateString"
            >
              <div>{{ item.dateString }}</div>
              <div>{{ item.place }}</div>
              <div>{{ item.message }}</div>
            </van-step>
          </van-steps>
          <!-- <el-table
            v-loading="submitting"
            :data="orderList[0].details || []"
            row-key="index"
            border
            size="mini"
            stripe
            style="width: 100%; margin-top: 30px; color: #222; font-weight: normal; font-size: 13px;"
          >
            <el-table-column
              prop="dateString"
              label="日期时间"
              align="center"
            />
            <el-table-column
              prop="place"
              label="服务地点"
              align="center"
            />
            <el-table-column
              prop="message"
              label="详细内容"
              align="center"
            />
          </el-table> -->
          <!-- <div class="order_table">
      <table border="1" rules="all" class="table_table">
        <tr>
          <th>日期时间</th>
          <th>服务地点</th>
          <th>详细内容</th>
        </tr>
        <tr v-for="(item, index) in list[0].details" :key="index">
          <td>{{ item.dateString }}</td>
          <td>{{ item.place }}</td>
          <td>{{ item.message }}</td>
        </tr>
      </table>
    </div> -->
        </div>
        <div v-show="orderList.length > 1" class="order_order_table">
          <el-table
            v-loading="submitting"
            :data="orderList"
            row-key="boxNo"
            stripe
            style="color: #222; font-weight: normal; font-size: 13px;"
          >
            <el-table-column
              prop="boxNo"
              label="运单号"
              align="center"
              size="mini"
              width="140"
            />
            <el-table-column label="身份证" align="center">
              <template slot-scope="scope">
                <span>{{ scope.row.idPresent == true ? "√" : "未上传" }}</span>
              </template>
            </el-table-column>
            <!-- <el-table-column prop="message" label="收件日期" /> -->
            <el-table-column
              prop="receiveDate"
              label="收件日期"
              align="center"
              width="148"
            >
              <template slot-scope="scope">
                <span>{{ scope.row.receiveDate | dayjss }}</span>
              </template>
            </el-table-column>
            <el-table-column
              prop="destination"
              width="200"
              label="目的地"
              align="center"
            />
            <el-table-column
              prop="reminder"
              label="状态"
              align="center"
              width="300"
            >
              <template slot-scope="{ row }">
                <div>
                  {{ row.reminder | reminder }}
                </div>
              </template>
            </el-table-column>
            <!-- {{ item.details[item.details.length - 1].dateString }} -->
            <el-table-column label="更新时间" align="center" width="148">
              <template slot-scope="scope">
                <span v-if="scope.row.details">{{
                  scope.row.details[scope.row.details.length - 1].dateString
                }}</span>
              </template>
            </el-table-column>
            <el-table-column prop="lastInfo" label="当前位置" align="center" />
            <el-table-column label="追踪" align="center">
              <template slot-scope="{ row }">
                <a :href="`/Search-wx.aspx?no=${row.boxNo}`">
                  <el-link type="primary">详细追踪</el-link>
                </a>
              </template>
            </el-table-column>
          </el-table>
        </div>

        <!-- <div v-if="list2.length > 1">
          <div class="order_table">
            <table border="1" rules="all" class="table_table">
              <tr>
                <th>运单号</th>
                <th>身份证</th>
                <th>收件日期</th>
                <th>目的地</th>
                <th>状态</th>
                <th>更新时间</th>
                <th>当前位置</th>
                <th>追踪</th>
              </tr>
              <tr v-for="(item, index) in list2" :key="index">
                <td width="10%">{{ item.boxNo }}</td>
                <td width="10%">
                  {{ item.idPresent == true ? "√" : "未上传" }}
                </td>
                <td width="10%">
                  {{ item.receiveDate | dayjss }}
                </td>
                <td width="10%">{{ item.destination }}</td>
                <td width="25%">{{ item.reminder }}</td>
                <td width="10%">
                  {{ item.details[item.details.length - 1].dateString }}
                </td>
                <td width="10%">{{ item.lastInfo }}</td>
                <td width="10%" class="zhuizong">详细追踪</td>
              </tr>
            </table>
          </div>
        </div> -->
      </div>
    </div>
    <!-- aside right -->
    <aside
      class="ui-aside asideRight w23"
      :style="
        showAsideRight
          ? 'top: 0px; bottom: 0px; right: -1px; width: 120px;'
          : 'top: 0px; bottom: 0px; right: -4rem; width: 0'
      "
    >
      <ul>
        <li>
          <a href="/">首页</a>
        </li>
        <!-- <li>
            <a href="news.aspx">活动公告</a>
          </li> -->
        <li>
          <a href="/service">特色服务</a>
        </li>
        <li>
          <a href="/about-wx.aspx">关于三象</a>
        </li>
        <li><a href="/contact">联系三象</a></li>
      </ul>
    </aside>
    <!-- </form> -->
    <!--START: Footer -->

    <div class="foot">
      <div class="copyright w1180 tc">
        <p>
          Copyright © 2017-2020 三象速递 &nbsp;&nbsp;&nbsp;&nbsp;联系电话：+61
          1300965539 &nbsp;&nbsp;&nbsp;&nbsp;地址：Po Box 6635, Point Cook, VIC
          3030&nbsp;&nbsp;&nbsp;&nbsp;<a
            href="http://www.kuaidi100.com/"
            target="_blank"
            >快递查询</a
          >
        </p>
      </div>
    </div>
    <!-- 代码部分end -->
  </div>
</template>

<script>
import dayjs from "dayjs";

export default {
  filters: {
    dayjss(value) {
      if (value) {
        const utc = require("dayjs/plugin/utc");
        dayjs.extend(utc);
        return dayjs.utc(value).format("YYYY-MM-DD");
      } else {
        return "";
      }
    },
    reminder(value) {
      if (!value) {
        return value;
      }
      return value.replace(/EWE/g, "三象");
    }
  },
  data() {
    return {
      no: this.$route.query.no,
      orderList: [
        {
          boxNo: "",
          details: [
            {
              dateString: "",
              place: "",
              message: ""
            }
          ]
        }
      ],
      orderInput: this.$route.query.no || "",
      showAsideRight: false,
      submitting: false
    };
  },
  computed: {
    singleData() {
      const arr = this.orderList[0].details || [];
      return arr.reverse();
    }
  },
  created() {
    if (this.orderInput) {
      this.getData(this.orderInput);
    }
  },
  watch: {
    "$route.query": function(q) {
      this.$nextTick(() => {
        this.getData(q.no);
      });
    }
  },
  methods: {
    startSearch() {
      this.$router.replace({
        query: {
          no: this.no,
          t: new Date().getTime()
        }
      });
    },
    getData(no) {
      no = no.toUpperCase();
      if (
        no.indexOf("SXS") === 0 ||
        no.indexOf("SXM") === 0 ||
        no.indexOf("SXV") === 0 ||
        no.indexOf("OIV") === 0 ||
        no.indexOf("BIQ") === 0 ||
        no.indexOf("CGT") === 0
      ) {
        // /oms/api/tracking/ewe/TrackingNumber1,TrackingNumber2,TrackingNumber3?IsDetailed=true
        // const url = `/oms/api/tracking/ewe/${no}`;
        // const url = `/oms/api/tracking/ewe/${no}?IsDetailed=true`;
        this.submitting = true;
        // const url = "/oms/api/tracking/query";
        // const url = "/oms/api/tracking/ewepost";
        const url = "/api/fadmin/daemon/logistics/tracking";
        this.$request(url, {
          method: "POST",
          data: {
            isDetailed: true,
            querystring: no.replace(/\r\n/g, ",").replace(/\n/g, ","),
            userid: "ss"
          }
        })
          .then(res => {
            this.submitting = false;
            if (res.code === 1) {
              this.$nextTick(() => {
                this.orderList = res.data;
              });
            }
            // this.orderList = { ...res };
          })
          .catch(() => {
            this.submitting = false;
          });
      } else {
        this.orderList = [
          {
            boxNo: null,
            isInput: false,
            idPresent: false,
            isReceived: false,
            // boxNo: no,
            transferNo: null,
            departure: null,
            destination: null,
            createDate: null,
            deliveryDate: null,
            receiveDate: null,
            transferName: null,
            transferRoute: null,
            lastInfo: null,
            deliveryStatusCn: null,
            deliveryStatus: 0,
            details: null,
            modified: false,
            inPipeline: false,
            reminder:
              "抱歉！未查到此运单信息，您可以通过单号查询6个月内的订单。",
            reference: null,
            articleCount: 0
          }
        ];
      }
    },
    searchData() {
      console.log(this.orderInput, "this.orderInput");
      if (this.orderInput) {
        this.getData(this.orderInput);
      }
    },
    go(row) {
      this.orderInput = row.boxNo;
      this.getData(row.boxNo);
    },
    changeMenu() {
      //移动端右侧滑出
      this.showAsideRight = true;
    },
    hidenAsideRight() {
      this.showAsideRight = false;
    },
    goUpload() {
      this.$router.push("/Network2-wx.aspx");
    }
  }
};
</script>

<style scoped>
.tips {
  padding: 8px;
  line-height: 24px;
  background-color: #ffffff;
  border: 1px solid #d2d2d2;
  text-align: left;
  margin-top: 20px;
}
.search_ewe {
  text-align: left;
}
.top_banner {
  position: relative;
}
.top_img {
  display: block;
  width: 100%;
}
.con_con {
  padding: 0 15px;
}

.con_con_top {
  padding: 20px 0 30px 0;
}
.top_font {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 32px;
  font-weight: 500;
  text-align: left;
}
.line {
  width: 60px;
  border-top: 4px solid #000;
  margin: 0 auto;
}
.bottom {
  padding: 15px 0;
  text-align: left;
}
.bottom1 {
  padding: 30px 0;
}
.font_title_ewe {
  font-size: 20px;
}
.title_en {
  font-size: 14px;
  color: rgba(0, 0, 0, 0.4);
  margin-top: 4px;
}
.search_con {
  /* display: flex; */
  /* justify-content: space-between; */
  /* flex-wrap: wrap; */
  /* padding-top: 20px; */
}
.input_class {
  background: none;
  outline: none;
  border: 1px solid rgba(0, 0, 0, 0.2);
  line-height: 20px;
  /* min-width: 500px; */
  width: 100%;
}
.btn_search {
  margin-top: 10px;
  height: 60px;
  line-height: 60px;
  box-sizing: border-box;
  background-color: #079af1;
  cursor: pointer;
  color: #fff;
  /* padding: 20px 80px; */
  padding: 0 80px;
  font-size: 18px;
  border-radius: 6px;
}
.line_line {
  /* margin: 30px 0 20px 0; */
  border-top: 1px dashed rgba(0, 0, 0, 0.2);
}
.order_det {
  text-align: left;
  /* margin: 50px 0; */
}
.order_order_table {
  margin-bottom: 50px;
}
.order_item {
  margin-top: 3px;
  font-size: 14px;
  color: rgba(0, 0, 0, 0.8);
}
.item_title {
  width: 100px;
  display: inline-block;
}
.item_det {
  display: inline-block;
}
.order_table {
  /* margin-top: 50px; */
  padding-top: 30px;
}
/* .table_table {
  width: 100%;
  border-color: rgba(0, 0, 0, 0.2);
  color: rgba(0, 0, 0, 0.8);
  font-weight: 400;
} */
.zhuizong {
  cursor: pointer;
  color: #079af1;
}
.footer_ewe {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
}
</style>
